.chatroom {
  width: 100vw; /* Full viewport width */
  max-width: 600px;
  height: 100vh; /* Full viewport height */
  margin: 0;
  border: none; /* Remove borders for a seamless look */
  border-radius: 0; /* Remove border-radius to fit the screen edges */
  overflow: hidden;
  box-shadow: none; /* Remove box-shadow for a flatter, native app feel */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.message-history {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #b8cee0;
}

.chat-row {
  display: flex;
  margin-bottom: 5px;
}

.chat-row.own {
  justify-content: end;
}

.chat-row.other {
  justify-content: start;
}

.chat-row.system {
  display: flex;
  justify-content: center;
}

.message {
  max-width: 60%; /* Limit width for better readability */
  padding: 10px 15px;
  border-radius: 10px; /* Less border radius for a more subtle look */
  font-size: 14px;
  line-height: 1.4;
  border: 1px solid #e6e6e6; /* Default border for all messages */
  position: relative; /* Relative positioning for the tail */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for better appearance */
}

.message.own {
  background-color: #f9e000;
  color: #333333;
  border: 1px solid #f9e000; /* Add border line for user messages */
}

.message.other {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #ffffff;
}

.message.system {
  border: 0;
  box-shadow: none;
  text-align: center;
}

/* Adding tails to the chat bubbles using ::after pseudo-elements */
.message.own::after {
  content: "";
  position: absolute;
  top: 63%;
  right: -12px;
  transform: translateY(-5%);
  border-width: 7px 0 9px 18px;
  border-style: solid;
  border-color: transparent transparent transparent #f9e000; /* Matches the background color */
}

.message.other::after {
  content: "";
  position: absolute;
  top: 83%;
  left: -9px;
  transform: translateY(-50%);
  border-width: 2px 9px 10px 0;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent; /* Matches the background color */
}

.message strong {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}

.message-input {
  padding: 15px;
  display: flex;
  gap: 10px;
  border-top: 1px solid #e6e6e6;
  background-color: #ffffff;
}

.message-input .ant-input {
  flex-grow: 1;
  font-size: 16px;
  border-radius: 20px;
  padding-left: 15px;
}

.message-input .ant-btn {
  border-radius: 20px;
}