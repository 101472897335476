.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 300px;
}

.loading {
    width: 100%;
    text-align: center;
    padding: 20px;
    font-size: 18px;
}
